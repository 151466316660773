@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  h1 {
    @apply text-4xl py-2;
  }
  h2 {
    @apply text-3xl py-2;
  }
  h3 {
    @apply text-2xl py-2;
  }
  h4{
    @apply text-xl py-2;
  }
  table{
    @apply border;
  }
  tr{
    @apply border
  }
  td{
    @apply border px-2;
  }
  th{
    @apply border;
  }
  ul{
    @apply ml-2 list-inside;
  }
  li{
    @apply my-2 bg-black/25 p-2 rounded
  }
} */



:root{
  --bgMain: rgb(23, 99, 134);
  --bgHF: rgb(41, 131, 173);
}

html, body{
  font-family: 'Kanit';
  color:#d4d4d4;
  margin: 0;
  padding:0;
  height: 100%;
}

#root{
  min-height: 100vh;
  margin:0;
  display: flex;
  flex-direction: column;
}

.page p{
  margin: 1rem;
  text-indent: 1cm;
  text-align:justify;
  text-justify: inter-word;
}
.icons img, .icons svg{
  margin: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
}
.footer img{
  width:50px;
  height:50px;
}

@media (min-width: 768px){
  .icons img, .icons svg{
    margin: 0.5rem;
    width: 3rem;
    height: 3rem;
  }
}